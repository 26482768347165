import React from "react";

import { Avatar, Col, Card, List, Space } from "antd";
import { Link } from "gatsby";
import "src/components/productCategories/style.scss";

import { CheckOutlined, PlusCircleOutlined } from "@ant-design/icons";

import laFlorida from "src/components/referencias/images/exterior/climateCoating-thermoProtect-la-florida-thumbnail.png";
import elParaiso from "src/components/referencias/images/exterior/climateCoating-thermoProtect-el-campanario-el-paraiso.jpg";
import laMoraleja from "src/components/referencias/images/exterior/climateCoating-thermoProtect-la-moraleja-madrid-thumbnail.jpg";
import sotogrande from "src/components/referencias/images/exterior/climateCoating-thermoProtect-sotogrande-cadiz-thumbnail.jpg";
import manuelTovar from "src/components/referencias/images/exterior/climateCoating-thermoProtect-coworking-manuel-tovar-thumbnail.jpg";

import lcFirst from "utils/lcFirst";

import thermoProtectAvatarImg from "src/components/products/images/thermoProtectAvatar.jpg";

const { Meta } = Card;

export const ProductCategoryExteriorComponent = ({}) => {
  const listData = [
    {
      title: "ThermoProtect - Aplicación en exterior de Unifamiliar",
      list: ["Ahorro", "Estética", "Protección", "Medio Ambiente"],
      imgSrc: laFlorida,
      productName: "ThermoProtect",
      avatar: thermoProtectAvatarImg,
      productTitle: "ThermoProtect",
      description:
        "Unifamiliar en la urbanización la Florida en la A-6, a las afueras de Madrid",
      content:
        "Unifamiliar utilizado en el rodaje del programa 'En tu Casa o en la Mía', de Bertín Osborne",
      linkToReferencia: "/referencias-exterior/casa-de-bertin-osborne",
    },
    {
      title: "ThermoProtect - Complejo el Campanario el Paraíso",
      list: ["Ahorro Energético del conjunto", "Protección de la edificación"],
      imgSrc: elParaiso,
      productName: "ThermoProtect",
      avatar: thermoProtectAvatarImg,
      productTitle: "ThermoProtect",
      description: "Complejo hotelero en Estepona, zona de Guadalmina",
      content:
        "En Guadalmina, se encuentra el complejo de 40 lujosas viviendas, 98 apartamentos y una Residencia de Mayores que disfrutan de las ventajas de ClimateCoating ThermoProtect",
    },
    {
      title: "ThermoProtect - Oficinas y Espacio Coworking Urban Lab en Madrid",
      list: [
        "Estética y Protección del Edificio",
        "Estabilidad de los colores",
        "Temperatura Interior",
      ],
      imgSrc: manuelTovar,
      productName: "ThermoProtect",
      avatar: thermoProtectAvatarImg,
      productTitle: "ThermoProtect",
      description: "Pintada de negro ",
      content:
        "La vanguardista elección de colores del edificio obliga a contar con el revestimiento de mayor calidad y estabilidad cromática",
      linkToReferencia:
        "/referencias-exterior/oficinas-espacio-coworking-urban-lab-madrid",
    },
    {
      title: "ThermoProtect - Unifamiliar en la Moraleja Madrid",
      list: [
        "Ahorro Energético",
        "Mejora Estética",
        "Protección de la edificación",
      ],
      imgSrc: laMoraleja,
      productName: "ThermoProtect",
      avatar: thermoProtectAvatarImg,
      productTitle: "ThermoProtect",
      description:
        "Unifamiliar en la urbanización La Moraleja a las afueras de Madrid",
      content: "",
    },
    {
      title: "ThermoProtect - Unifamiliar en Sotogrande (Cádiz)",
      list: [
        "Respecto al medio ambiente",
        "Mejora Estética",
        "Protección de la edificación",
      ],
      imgSrc: sotogrande,
      productName: "ThermoProtect",
      avatar: thermoProtectAvatarImg,
      productTitle: "ThermoProtect",
      description: "Unifamiliar en la urbanización de Sotogrande - Cádiz",
      content: "",
    },
  ];

  const IconText = ({ icon, text, linkToReferencia }) => (
    <Space>
      {linkToReferencia ? (
        <Link className="thermoProtect bold" to={linkToReferencia}>
          {React.createElement(icon)}
          {"  "}
          {text}
        </Link>
      ) : (
        <>
          {React.createElement(icon)}
          {text}
        </>
      )}
    </Space>
  );

  return (
    <div className="ccContent referencias">
      <List
        {...{
          itemLayout: "vertical",
          size: "large",
          pagination: {
            onChange: (page) => {
              console.log(page);
            },
            pageSize: 5,
          },
          dataSource: listData,
          footer: (
            <div>
              Referencias de proyectos que han usado{" "}
              <b>ClimateCoating en España</b>
            </div>
          ),

          renderItem: ({
            title,
            list,
            avatar,
            productName,
            description,
            content,
            imgSrc,
            linkToReferencia,
          }) => (
            <List.Item
              key={title}
              actions={list
                .map((feature, key) => (
                  <IconText
                    {...{
                      icon: CheckOutlined,
                      text: feature,
                      key,
                    }}
                  />
                ))
                .concat(
                  linkToReferencia ? (
                    <IconText
                      {...{
                        icon: PlusCircleOutlined,
                        text: "Más Info",
                        key: "masInfo",
                        linkToReferencia,
                      }}
                    />
                  ) : (
                    []
                  )
                )}
              extra={<img width={272} alt="logo" src={imgSrc} />}
            >
              <List.Item.Meta
                avatar={<Avatar src={avatar} />}
                title={
                  <Link
                    to={`/productos/${productName.toLowerCase()}`}
                    className={lcFirst(productName)}
                  >
                    {title}
                  </Link>
                }
                description={description}
              />
              {content}
            </List.Item>
          ),
        }}
      />
    </div>
  );
};
